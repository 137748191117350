import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useState, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Plug from './Plug';
import Results from './Results';
import { useNavigation } from '@react-navigation/native';
import Details from './Details';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import {getUserData} from './src/graphql/queries';
import { AntDesign, FontAwesome } from '@expo/vector-icons';


const {width, height} = Dimensions.get('screen')
const Stack = createNativeStackNavigator();


import { Amplify } from 'aws-amplify';
import awsExports from './src/aws-exports';
Amplify.configure(awsExports);

function HomeScreen() {
    return (
      <View style={{ flex: 1, backgroundColor: '#f2f2f2', alignItems: 'center', justifyContent: 'center'}}>
        <Plug/>
      </View>
    );
  }


  function ResultsScreen() {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Results/>
      </View>
    );
  }

  function DetailsScreen() {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
       <Details/>
      </View>
    );
  }



export default function App() {

  const [choose,setChoose] = useState('Main');
  const [emailAddress, setEmailAddress] = useState('');
  const [password ,setPassword] = useState('');
  const [idNumber ,setIdNumber] = useState('');
  const [userInfo, setUserInfo] = useState();
  const [spinning, setSpinning] = useState(false);
  const [errorMs ,setErrorMs] = useState('');
  const [secureEnt, setSecureEnt] = useState(true)

  const checkAuth = async()=>{

    const userAuthenticated =  await Auth.currentAuthenticatedUser();
    const userSub = userAuthenticated.attributes.sub;

    try{
      await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
      const userd = ef.data.getUserData

      setUserInfo(userd)

      setChoose('donr')
      return
    }).catch((ee)=> {
      console.error(ee)
    })

} catch (e){
  console.error(e)
  setChoose('Main'),
  console.log('Not authenticated')
}

  }

  useEffect(() => {
    checkAuth()
  }, [userInfo])
  
  const signIn = async()=> {
    setSpinning(true)

    try {
     await Auth.signIn(emailAddress, password).then((ee)=> {
      setChoose('In')
      setSpinning(false)
     })
    } catch (error) {
        console.error(error),
        setErrorMs(error),
        setSpinning(false)
    }

  }

  if(choose === 'Main'){
      return(
        <View style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.8)', alignItems: 'center', justifyContent: 'center'}}>
            <View style={{height: height / 2, width: height / 2, backgroundColor: 'white', borderRadius: 10, padding: 30}}>
              <Text style={{fontWeight: 'bold', fontSize: 30,}}>Login</Text>
              <Text style={{fontSize: 12}}>Master Of The High Court</Text>

              <View style={{marginTop: 30}}>
                <Text style={{marginBottom: 15}}>E-Mail Address</Text>
                <TextInput placeholder='E-Mail Address' value={emailAddress} onChangeText={setEmailAddress} style={{width: '80%', height: 50, borderColor: 'gray', borderWidth: 1, paddingLeft: 20}}/>
              </View>

              <View style={{marginTop: 20}}>
                <Text style={{marginBottom: 15}}>Password</Text>
                <View style={{width: '80%', height: 50}}>
                                  <TextInput placeholder='Password' value={password} onChangeText={setPassword}  secureTextEntry={secureEnt} style={{width: '100%', height: 50, borderColor: 'gray', borderWidth: 1, paddingLeft: 20}}/>
                                  <View style={{position: 'absolute', right: 15, top: 15}}>
              {secureEnt ? <TouchableOpacity onPress={()=> setSecureEnt(!secureEnt)}>
                <FontAwesome name='eye-slash' size={15} color={'black'} />
              </TouchableOpacity> : <TouchableOpacity onPress={()=> setSecureEnt(!secureEnt)}>
                <FontAwesome name='eye' size={15} color={'black'} />
              </TouchableOpacity>}
                           
              </View> 
                </View>
              
                
              
              </View>

              <View style={{fontWeight: 'bold', fontSize: 12, color: 'red'}}>{errorMs.message}</View>

              <View style={{marginTop: 20}}>
                <TouchableOpacity onPress={()=> {signIn()}} style={{height: 50, width: '80%', backgroundColor: '#1b85d1', alignItems: 'center', justifyContent: 'center'}}>
                  <View style={{position: 'absolute', left: 15}}>
                    {spinning && <ActivityIndicator size={'small'} color={'#fcfcfc'} />}
                  </View>
                  <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Log Me In</Text>
                </TouchableOpacity>
              </View>


            </View>
        </View>
      )
  }

  

  
  
  return (
    <View style={{flex: 1}}>
      
      <NavigationContainer>
      <Stack.Navigator initialRouteName="ID">
        <Stack.Screen name="ID" component={HomeScreen} />
        <Stack.Screen name="Results" component={ResultsScreen} />
        <Stack.Screen name="Details" component={DetailsScreen} />
      </Stack.Navigator>
    </NavigationContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
